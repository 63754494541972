<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="电站列表"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      title="可用站点配置"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit v-if="showDrawer" ref="addOrEdit" :rowInfo="rowInfo"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { marketManage } from '@/service/apis';
  import AddOrEdit from './AddOrEdit.vue';
  import { code } from '@/utils';

  export default {
    name: 'availableSitesConfigList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        selectItems: [],
        showDrawer: false,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],

        tableOperateButtons: [
          {
            enabled: () => code('operation:config:usable:edit'),
            // eslint-disable-next-line
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doEdit(row);
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:config:usable:detail'),
            // eslint-disable-next-line
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doDetail(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '客户名称', prop: 'orgName' },
          { label: '客户分类', prop: 'configTypeDesc' },
          { label: '备注信息', prop: 'remark' },
          { label: '修改人', prop: 'modifiedName' },
          { label: '修改时间', prop: 'modifiedTime' },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          orgName: '',
          configTypeDesc: '',
        },
      };
    },
    computed: {
      rowSelectable() {
        return row => {
          const rowStatus = String(row.status);
          if (['3', '4'].includes(rowStatus)) {
            return false;
          }
          return true;
        };
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '客户名称',
            placeholder: '请输入客户名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'orgTypeDesc',
            label: '客户分类',
            placeholder: '请选择客户分类',
            data: this.dicType(),
          },
        ];
      },
    },
    created() {
      this.searchTableList();
    },
    methods: {
      doEdit(row) {
        this.rowInfo = row;
        this.showDrawer = true;
      },
      doDetail(row) {
        this.$router.push({
          path: '/operationCenter/operationConfig/availableSitesConfig/details',
          query: {
            id: row.orgId,
          },
        });
      },
      dicType() {
        // 客户分类（1：内部客户，2：充电客户）
        return [
          { id: '1', name: '内部客户' },
          { id: '2', name: '充电客户' },
        ];
      },
      searchTableList() {
        const data = {
          ...this.searchParams,
          size: this.pageInfo.size,
          current: this.pageInfo.current,
        };
        marketManage.availableStationIsConfig(data).then(res => {
          console.log('availableStationIsConfig res', res);
          const { records, current, total } = res;
          this.tableData = records || [];
          this.pageInfo.current = current;
          this.pageInfo.total = total;
        });
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        if (this.searchParams.orgTypeDesc === '1') {
          this.searchParams.orgTypeDesc = '内部客户';
        }
        if (this.searchParams.orgTypeDesc === '2') {
          this.searchParams.orgTypeDesc = '充电客户';
        }
        this.pageInfo.current = 1;
        this.searchTableList();
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            // 点击确认取消保存后，要重置表单
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss" scoped></style>
