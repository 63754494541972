<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="客户名称" prop="orgName">
      <div>{{ rowInfo.orgName }}</div>
    </ykc-form-item>
    <ykc-form-item label="客户分类" prop="configTypeDesc">
      <div>{{ rowInfo.configTypeDesc || '——' }}</div>
    </ykc-form-item>
    <ykc-form-item label="电站维度" prop="stationScopeType" required>
      <ykc-radio
        :data="stationScopeType"
        v-model="ruleForm.stationScopeType"
        @change="changeAvailable"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="选择电站" prop="dataIdList" v-if="ruleForm.stationScopeType !== '4'">
      <ykc-tree
        v-model="checkAll"
        :data="treeData"
        :props="treeProps"
        :nodeKey="treeNodeKey"
        ref="stationTree"
        @check-change="handleTreeCheckChange"></ykc-tree>
    </ykc-form-item>
    <ykc-form-item label="备注" prop="remark">
      <ykc-input
        v-model="ruleForm.remark"
        type="textarea"
        placeholder="备注"
        maxlength="500"></ykc-input>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { barrierPage, treeUtils, marketManage } from '@/service/apis';

  export default {
    props: {
      rowInfo: {
        type: Object,
      },
    },
    data() {
      return {
        stationScopeType: [
          {
            id: '1',
            name: '按商户',
          },
          {
            id: '2',
            name: '按电站',
          },
          {
            id: '3',
            name: '按电站分组',
          },
          {
            id: '4',
            name: '按全部电站',
          },
        ],
        detail: {},
        rules: {
          dataIdList: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          remark: [{ required: false, trigger: 'blur', message: '请输入备注' }],
        },
        checkAll: 'true',
        ruleForm: {
          id: '',
          orgId: '',
          remark: '',
          dataIdList: [],
          stationScopeType: '',
        },
        treeData: [],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeNodeKey: 'id',
      };
    },
    computed: {
      formData() {
        if (this.rowInfo) {
          return {
            id: this.rowInfo.id,
            orgId: this.rowInfo.orgId,
            dataIdList: this.rowInfo.data.dataIdList,
            stationScopeType: this.rowInfo.data.stationScopeType,
            remark: this.rowInfo.remark,
          };
        }
        return {
          id: this.ruleForm.id,
          orgId: this.ruleForm.orgId,
          dataIdList: this.ruleForm.dataIdList,
          stationScopeType: this.ruleForm.stationScopeType,
          remark: this.ruleForm.remark,
        };
      },
    },
    created() {
      this.init();
      // this.getStationTree();
    },
    methods: {
      /* 获取详情 */
      init() {
        const reqParams = {
          id: this.rowInfo.orgId,
        };
        marketManage.StationIsConfigDetail(reqParams).then(res => {
          console.log('StationIsConfigDetail', res);
          this.detail = res || {};
          this.ruleForm.id = this.detail.id;
          this.ruleForm.orgId = this.detail.orgId;
          this.ruleForm.stationScopeType = this.detail.data.stationScopeType;
          this.ruleForm.remark = this.detail.remark;
          // this.ruleForm.dataIdList = this.detail.data.dataIdList;
          this.getStationTree();
          // this.$refs.stationTree.setCheckedKeys(this.detail.data.dataIdList, true);
        });
      },

      /* 获取电站 */
      getStationTree() {
        if (this.ruleForm.stationScopeType === '1') {
          // 按商户选择
          treeUtils
            .operatorStations({})
            .then(res => {
              this.treeProps = {
                label: 'operatorName',
                children: 'subNotes',
                disabled: 'disabled',
              };
              this.treeNodeKey = 'operatorId';
              const listData = res || [];
              const kayMap = { operatorName: 'operatorName', operatorId: 'operatorId' };
              const setEmpty = (data, keyMap) => {
                const objs = Object.keys(data).reduce((newData, key) => {
                  const newKey = keyMap[key] || key;
                  newData[newKey] = data[key];
                  return newData;
                }, {});
                return objs;
              };
              this.$nextTick(() => {
                this.treeData = listData.map(item => {
                  return setEmpty(item, kayMap);
                });
                this.fillInitData();
              });
            })
            .catch(() => {
              this.treeData = [];
            });
        } else if (this.ruleForm.stationScopeType === '2') {
          // 按电站选择
          barrierPage
            .findStationList({})
            .then(res => {
              console.log('findStationList', res);
              this.treeProps = {
                label: 'stationName',
                children: 'subNotes',
                disabled: 'disabled',
              };
              this.$nextTick(() => {
                this.treeNodeKey = 'stationId';
                this.treeData = res || [];
                this.fillInitData();
              });
            })
            .catch(() => {
              this.treeData = [];
            });
        } else if (this.ruleForm.stationScopeType === '3') {
          // 按电站分组
          treeUtils
            .operatorStationGroup({
              operatorId: '',
            })
            .then(res => {
              this.treeProps = {
                label: 'groupName',
                children: 'subNotes',
                disabled: 'disabled',
              };
              this.$nextTick(() => {
                this.treeNodeKey = 'id';
                this.treeData = res || [];
                this.fillInitData();
              });
            })
            .catch(() => {
              this.treeData = [];
            });
        }
        // this.fillInitData();
      },
      /**
       *填充初始化数据
       */
      fillInitData() {
        if (this.ruleForm.stationScopeType !== '4') {
          if (this.detail.data.dataIdList.length > 0) {
            this.ruleForm.dataIdList = this.detail.data.dataIdList;
            this.ruleForm.dataIdList = this.fillTreeSelectedIds(this.detail.data.dataIdList);
            this.selectTreeNodes(this.$refs.stationTree, this.ruleForm.dataIdList);
          }
        } else {
          this.ruleForm.dataIdList = [];
        }
      },
      /**
       * 选择树形控件
       * @param tree
       * @param selectedNodes
       */
      selectTreeNodes(tree, selectedNodes) {
        if (this.ruleForm.stationScopeType === this.detail.data.stationScopeType) {
          this.$nextTick(() => {
            tree.setCheckedKeys(selectedNodes, true);
          });
        } else {
          selectedNodes = [];
          this.$nextTick(() => {
            tree.setCheckedKeys(selectedNodes, true);
          });
        }
      },
      fillTreeSelectedIds(records) {
        const selectedIds = [];
        records.forEach(item => {
          selectedIds.push(item);
        });
        return selectedIds;
      },
      handleTreeCheckChange() {
        this.ruleForm.dataIdList = [];
        this.ruleForm.dataIdList = this.$refs.stationTree.getCheckedKeys(true);
      },

      changeAvailable(val) {
        console.log('val', val);
        this.getStationTree();
      },
      /* 保存 */
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            const reqParams = this.ruleForm;
            reqParams.data = {
              dataIdList: this.ruleForm.dataIdList,
              stationScopeType: this.ruleForm.stationScopeType,
            };
            delete reqParams.dataIdList;
            delete reqParams.stationScopeType;
            marketManage.updateStationIsConfig(reqParams).then(() => {
              this.$message({
                message: '保存成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
